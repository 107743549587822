import {
  AgreementStatus,
  RoundingRule,
  SettlementType,
  agreementStatuses,
  roundingRules,
  settlementTypes,
} from '@/connect';

export function formatSettlementType(settlementType: SettlementType): string {
  return settlementTypes.get(settlementType) ?? '';
}

export function formatRoundingRule(roundingRule: RoundingRule): string {
  return roundingRules.get(roundingRule) ?? 'No rounding';
}

export function getAgreementStatusName(
  status: AgreementStatus,
  isIncoming: boolean
): string | undefined {
  if (status === AgreementStatus.DRAFT) {
    return isIncoming ? 'Pending my approval' : 'Pending CP approval';
  }
  return agreementStatuses.get(status)?.name;
}

export function getAgreementStatusColor(
  status: AgreementStatus,
  isIncoming: boolean
): string | undefined {
  if (!isIncoming && status === AgreementStatus.DRAFT) {
    return 'gray';
  }
  if (isIncoming && status === AgreementStatus.REJECTED) {
    return 'gray';
  }
  return agreementStatuses.get(status)?.color;
}
