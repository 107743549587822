import { Raw } from '@/modules/common/helpers/api';
import { OmsOrderRequestDetails } from './common/oms-order-request-details';

export type RawModifyOmsOrderRequest = Raw<ModifyOmsOrderRequest, 'orderRef'>;

export class ModifyOmsOrderRequest extends OmsOrderRequestDetails {
  public readonly orderRef: string;

  protected constructor(data: ModifyOmsOrderRequest) {
    super(data);

    this.orderRef = data.orderRef;
  }

  public static fromModel(data: ModifyOmsOrderRequest): ModifyOmsOrderRequest {
    return new ModifyOmsOrderRequest(data);
  }

  public static toData(data: ModifyOmsOrderRequest): RawModifyOmsOrderRequest {
    return {
      ...OmsOrderRequestDetails.toData(data),
    };
  }
}
