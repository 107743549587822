import { DeepPartial, Raw } from '@/modules/common/helpers/api';

export type RawAgreementInfo = Raw<AgreementInfo>;

export class AgreementInfo {
  public id: string;
  public shortName: string;
  public displayId: string;

  protected constructor(data: RawAgreementInfo) {
    this.id = data.id;
    this.shortName = data.shortName;
    this.displayId = data.displayId;
  }

  public static fromData(data: RawAgreementInfo): AgreementInfo {
    return new AgreementInfo(data);
  }

  public static toData(model: AgreementInfo): RawAgreementInfo {
    return {
      id: model.id,
      shortName: model.shortName,
      displayId: model.displayId,
    };
  }

  public static mock(data?: null | DeepPartial<RawAgreementInfo>): AgreementInfo {
    return AgreementInfo.fromData(AgreementInfo.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawAgreementInfo>): RawAgreementInfo {
    return {
      id: '0',
      shortName: 'Super awesome agreement',
      displayId: 'RV3QE2RV',

      ...data,
    };
  }
}
