import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { Security } from '@/modules/common/models';
import { OrderSide } from '@/modules/marketplace/types/marketplace';
import { OmsOrderRequestDetails } from './common/oms-order-request-details';

export type RawCreateOmsOrderRequest = Raw<CreateOmsOrderRequest, { equityId: string }, 'security'>;

export class CreateOmsOrderRequest extends OmsOrderRequestDetails {
  public security: Security;
  public side: OrderSide;

  protected constructor(data: CreateOmsOrderRequest) {
    super(data);

    this.security = data.security;
    this.side = data.side;
  }

  public static fromModel(data: CreateOmsOrderRequest): CreateOmsOrderRequest {
    return new CreateOmsOrderRequest(data);
  }

  public static toData(data: CreateOmsOrderRequest): RawCreateOmsOrderRequest {
    return {
      ...OmsOrderRequestDetails.toData(data),
      equityId: data.security.id,
      side: data.side,
    };
  }
}

export type RawCreateOmsOrderResponse = Raw<CreateOmsOrderResponse>;

export class CreateOmsOrderResponse {
  public orderRef: string;
  public clientOrderRef: string;

  protected constructor(data: RawCreateOmsOrderResponse) {
    this.orderRef = data.orderRef;
    this.clientOrderRef = data.clientOrderRef;
  }

  public static fromData(data: RawCreateOmsOrderResponse): CreateOmsOrderResponse {
    return new CreateOmsOrderResponse(data);
  }

  public static mockData(data?: DeepPartial<RawCreateOmsOrderResponse>): RawCreateOmsOrderResponse {
    return {
      orderRef: 'abc',
      clientOrderRef: '',
      ...data,
    };
  }

  public static mock(data?: DeepPartial<RawCreateOmsOrderResponse>): CreateOmsOrderResponse {
    return CreateOmsOrderResponse.fromData(CreateOmsOrderResponse.mockData(data));
  }
}
