// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanrequestconsts.proto (package aurora.core.consts.loanrequest, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * possible states in the lifecycle of a loan request
 *
 * @generated from enum aurora.core.consts.loanrequest.LoanRequestStatus
 */
export enum LoanRequestStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ACCEPTED = 2;
   */
  ACCEPTED = 2,

  /**
   * @generated from enum value: REJECTED = 3;
   */
  REJECTED = 3,

  /**
   * @generated from enum value: EXPIRED = 4;
   */
  EXPIRED = 4,

  /**
   * @generated from enum value: CANCELED = 5;
   */
  CANCELED = 5,

  /**
   * @generated from enum value: AUTOMATCHED = 6;
   */
  AUTOMATCHED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanRequestStatus)
proto3.util.setEnumType(LoanRequestStatus, "aurora.core.consts.loanrequest.LoanRequestStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "ACCEPTED" },
  { no: 3, name: "REJECTED" },
  { no: 4, name: "EXPIRED" },
  { no: 5, name: "CANCELED" },
  { no: 6, name: "AUTOMATCHED" },
]);

