import { OpenLoan } from '@/modules/common/models';
import { AppState } from '@/store/store';
import { Role, hasPermission } from '@/utils/helpers/permissions';
import { TradingPermission, hasOneOfTradingPermission } from '@/utils/helpers/trading-permissions';
import { addMinutes, isAfter, parse } from 'date-fns';
import { Getter } from 'vuex';

/**
 * Export key names as a helper type
 */
export type GetterKeys = keyof typeof storeGetters;

export const storeGetters = {
  tfaEnabled: function (state) {
    if (state.loginState.user) {
      return state.loginState.user.tfaIsEnabled;
    }
    return false;
  } as Getter<AppState, AppState>,

  isLoggedIn: function (state) {
    return !!state.loginState.user;
  } as Getter<AppState, AppState>,

  canBorrow: function (state) {
    return hasOneOfTradingPermission(state.loginState, TradingPermission.BorrowerPermissions);
  } as Getter<AppState, AppState>,

  canLend: function (state) {
    return hasOneOfTradingPermission(state.loginState, TradingPermission.LenderPermissions);
  } as Getter<AppState, AppState>,

  canBorrowAndLend: function (state) {
    return (
      hasOneOfTradingPermission(state.loginState, TradingPermission.LenderPermissions) &&
      hasOneOfTradingPermission(state.loginState, TradingPermission.BorrowerPermissions)
    );
  } as Getter<AppState, AppState>,

  unreadNotifications: function (state) {
    return state.notifications.filter((item) => !item.readAt);
  } as Getter<AppState, AppState>,

  lastNotification: function (state) {
    return state.lastNotification;
  } as Getter<AppState, AppState>,

  initiatedAuctionCount: function (state) {
    return state.auctions.length;
  } as Getter<AppState, AppState>,

  hasTraderViewerRole: function (state: AppState) {
    return (
      state.loginState.user !== null &&
      hasPermission(state.loginState.user.roles, Role.TraderViewer)
    );
  } as Getter<AppState, AppState>,

  hasTraderUserRole: function (state) {
    return (
      state.loginState.user !== null && hasPermission(state.loginState.user.roles, Role.TraderUser)
    );
  } as Getter<AppState, AppState>,

  hasTraderAdminRole: function (state) {
    return (
      state.loginState.user !== null && hasPermission(state.loginState.user.roles, Role.TraderAdmin)
    );
  } as Getter<AppState, AppState>,

  hasOpsUserRole: function (state) {
    return (
      state.loginState.user !== null && hasPermission(state.loginState.user.roles, Role.OpsUser)
    );
  } as Getter<AppState, AppState>,

  hasBrokerAdminRole: function (state) {
    return (
      state.loginState.user !== null && hasPermission(state.loginState.user.roles, Role.BrokerAdmin)
    );
  } as Getter<AppState, AppState>,

  hasBrokerComplianceRepRole: function (state) {
    return (
      state.loginState.user !== null &&
      hasPermission(state.loginState.user.roles, Role.BrokerComplianceRep)
    );
  } as Getter<AppState, AppState>,

  lastVisitedSymbolOverview: function (state) {
    return state.lastVisitedSymbolOverview;
  } as Getter<AppState, AppState>,

  // col virtualisation is disabled in prod-like and review envs to facilitate E2E testing
  shouldSuppressGridVirtualisation: function (state) {
    return !state.clientConfig?.systemProdLike && state.clientConfig?.systemEnv != 'review';
  } as Getter<AppState, AppState>,

  isAfterCutOffTime: function (state) {
    return function (loan: OpenLoan): boolean {
      if (!loan.counterparty.cutOffTime) {
        return false;
      }

      const cutOff = parse(
        `${loan.counterparty.cutOffTime} ${state.clientConfig?.timezoneOffset}`,
        `${cutOffFromFormat} X`,
        state.currentTimeUTC
      );

      return isAfter(addMinutes(state.currentTimeUTC, 1), cutOff);
    };
  } as Getter<AppState, AppState>,
};

const cutOffFromFormat = 'HH:mm';
