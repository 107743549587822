// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/rateproposalconsts.proto (package aurora.core.consts.loanrateproposal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Different stages of a loan rate renegotiation
 *
 * @generated from enum aurora.core.consts.loanrateproposal.LoanRateProposalStatus
 */
export enum LoanRateProposalStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PROPOSED = 1;
   */
  PROPOSED = 1,

  /**
   * @generated from enum value: ACCEPTED = 2;
   */
  ACCEPTED = 2,

  /**
   * @generated from enum value: FINALIZED = 3;
   */
  FINALIZED = 3,

  /**
   * @generated from enum value: REJECTED = 4;
   */
  REJECTED = 4,

  /**
   * @generated from enum value: CANCELED = 5;
   */
  CANCELED = 5,

  /**
   * @generated from enum value: DROPPED = 6;
   */
  DROPPED = 6,

  /**
   * @generated from enum value: EXPIRED = 7;
   */
  EXPIRED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanRateProposalStatus)
proto3.util.setEnumType(LoanRateProposalStatus, "aurora.core.consts.loanrateproposal.LoanRateProposalStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "PROPOSED" },
  { no: 2, name: "ACCEPTED" },
  { no: 3, name: "FINALIZED" },
  { no: 4, name: "REJECTED" },
  { no: 5, name: "CANCELED" },
  { no: 6, name: "DROPPED" },
  { no: 7, name: "EXPIRED" },
]);

