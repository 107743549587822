import { AgreementStatus } from '@/connect/gen/consts/agreementconsts_pb';
import { RoundingRule } from '@/connect/gen/consts/loanconsts_pb';
import { SettlementType } from '@/connect/gen/consts/settlementconsts_pb';

export * from '@/connect/gen/consts/agreementconsts_pb';
export * from '@/connect/gen/consts/benchmarkconsts_pb';
export * from '@/connect/gen/consts/commonconsts_pb';
export * from '@/connect/gen/consts/loanbuyinconsts_pb';
export * from '@/connect/gen/consts/loanconsts_pb';
export * from '@/connect/gen/consts/loanhistoryconsts_pb';
export * from '@/connect/gen/consts/loanrecallcancelconsts_pb';
export * from '@/connect/gen/consts/loanrecallconsts_pb';
export * from '@/connect/gen/consts/loanrequestconsts_pb';
export * from '@/connect/gen/consts/loanreturnconsts_pb';
export * from '@/connect/gen/consts/omsconsts_pb';
export * from '@/connect/gen/consts/queryconsts_pb';
export * from '@/connect/gen/consts/rateproposalconsts_pb';
export * from '@/connect/gen/consts/settlementconsts_pb';
export * from '@/connect/gen/consts/uptimeconsts_pb';

export const agreementStatuses = new Map([
  [AgreementStatus.DRAFT, { name: 'Draft', color: 'blue' }],
  [AgreementStatus.ACTIVE, { name: 'Active', color: 'green' }],
  [AgreementStatus.REJECTED, { name: 'Rejected', color: 'red' }],
  [AgreementStatus.CANCELED, { name: 'Closed', color: 'gray' }],
  [AgreementStatus.INACTIVE, { name: 'Inactive', color: 'gray' }],
]);

export const settlementTypes = new Map([
  [SettlementType.NSCC, 'NSCC'],
  [SettlementType.BILATERAL, 'Bilateral'],
  [SettlementType.OCC, 'OCC'],
]);

export const roundingRules = new Map([
  [RoundingRule.NO_ROUNDING, 'no rounding'],
  [RoundingRule.UP_TO_NEAREST_0_DOT_01, 'up to nearest $0.01'],
  [RoundingRule.UP_TO_NEAREST_0_DOT_05, 'up to nearest $0.05'],
  [RoundingRule.UP_TO_NEAREST_0_DOT_10, 'up to nearest $0.10'],
  [RoundingRule.UP_TO_NEAREST_0_DOT_25, 'up to nearest $0.25'],
  [RoundingRule.UP_TO_NEAREST_1_DOT_0, 'up to nearest $1.00'],
]);
