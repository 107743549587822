<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ isEditMode ? 'Edit' : 'Create' }} <format-side :side="side" /> Order
          </span>
          <v-spacer />
          <span v-if="order" class="text--primary headline-2">
            {{ order.orderRef }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="!showSummary">
            <v-row class="mt-n4" dense>
              <v-col cols="6">
                <simple-security-search
                  v-model="security"
                  :autofocus="security === null"
                  :disabled="isEditMode"
                  :error-messages="errorMsgs['security']"
                  label="Security"
                  @input="$v.security.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-radio-group
                  v-model="orderType"
                  class="pl-2"
                  :disabled="isEditMode || !clientConfig.orderManagerV2Enabled"
                  label="Order Type"
                  @change="onOrderTypeChanged"
                >
                  <v-radio
                    v-for="option in filteredOrderTypeOptions"
                    :key="option.value"
                    class="ml-2"
                    :data-test-id="`order-type-${option.value}`"
                    :label="option.text"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <numeric-input
                  v-model="quantity"
                  :autofocus="security !== null"
                  data-test="quantity"
                  :error-messages="errorMsgs['quantity']"
                  :label="`Quantity${order && filled > 0 ? ` (executed: ${filled})` : ''}`"
                  :min="0"
                  :step="100"
                  type="integer"
                  @change="$v.quantity.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <numeric-input
                  v-model="rate"
                  :disabled="isMarketOrder"
                  :error-messages="errorMsgs['rate']"
                  label="Rate"
                  :precision="ratePrecision"
                  :step="0.25"
                  suffix="%"
                  type="decimal"
                  @change="$v.rate.$touch()"
                />
              </v-col>
            </v-row>

            <v-expansion-panels v-model="expandedPanel" data-test="advanced" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">Advanced Fields</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="6">
                      <v-select
                        v-model="timeInForceType"
                        item-text="text"
                        item-value="value"
                        :items="dependentTimeInForceOptions"
                        label="Time in Force"
                      />
                    </v-col>
                    <v-col cols="6">
                      <multiple-chip-agreement-selector
                        :agreements.sync="agreements"
                        label="Agreements"
                        :side="Side[side]"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6">
                      <numeric-input
                        v-model="minQuantity"
                        data-test="minQuantity"
                        :disabled="!clientConfig.orderbookMinQuantityEditable"
                        :error-messages="errorMsgs['minQuantity']"
                        label="Min. Quantity"
                        :max="quantity === null ? 1 : quantity - filled"
                        :min="1"
                        :step="100"
                        type="integer"
                        @change="$v.minQuantity.$touch()"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row v-if="errorMsgs.apiErrors.length || errorMsgs.form.length">
              <v-col class="px-1 col-6 offset-3">
                <div class="v-alert v-alert--dense text--primary text-body-2 text-center error">
                  {{ errorMsgs.apiErrors.join('\n') || errorMsgs.form.join('\n') }}
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            v-if="showSummary"
            v-shortkey="['enter']"
            @shortkey="
              submitForm(
                isEditMode ? (order?.routingStatus === 'ROUTED' ? 'ROUTED' : 'UNROUTED') : 'ROUTED'
              )
            "
          >
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <marketplace-order-summary
                  hide-routing
                  :order="{
                    ...formFields,
                    createdAt: order ? order.createdAt : undefined,
                    orderRef: order ? order.orderRef : undefined,
                  }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div class="v-alert v-alert--dense text--primary text-body-2 text-center error">
                  {{ errorMsgs.apiErrors.join('\n') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <template v-if="!showSummary">
              <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
              <v-btn
                v-if="errorMsgs.apiErrors.length === 0"
                color="primary"
                type="submit"
                @click="goToSummary()"
              >
                {{ isEditMode ? 'Edit' : 'Create' }}</v-btn
              >
            </template>

            <template v-if="showSummary">
              <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="goBack()">
                Back
              </v-btn>

              <aurora-btn-dropdown
                v-if="order"
                color="primary"
                data-test="save-button"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                :main-text="order.routingStatus === 'ROUTED' ? 'Save active' : 'Save inactive'"
                split
                timeframe="createLoans"
                @click="submitForm(order ? order.routingStatus : 'ROUTED')"
              >
                <v-list ref="route-actions" dense>
                  <v-list-item data-test="save-routed-menu-item" @click="submitForm('ROUTED')">
                    <v-list-item-title>Save active</v-list-item-title>
                  </v-list-item>
                  <v-list-item data-test="save-unrouted-menu-item" @click="submitForm('UNROUTED')">
                    <v-list-item-title>Save inactive</v-list-item-title>
                  </v-list-item>
                </v-list>
              </aurora-btn-dropdown>
              <aurora-btn-dropdown
                v-else
                color="primary"
                data-test="create-button"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                main-text="Create active"
                split
                timeframe="createLoans"
                @click="submitForm('ROUTED')"
              >
                <v-list ref="route-actions" dense>
                  <v-list-item data-test="create-routed-menu-item" @click="submitForm('ROUTED')">
                    <v-list-item-title>Create active</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    data-test="create-unrouted-menu-item"
                    @click="submitForm('UNROUTED')"
                  >
                    <v-list-item-title>Create inactive</v-list-item-title>
                  </v-list-item>
                </v-list>
              </aurora-btn-dropdown>
            </template>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import {
  IA_PRECISION,
  MAX_IA,
  MAX_RATE,
  RATE_PRECISION,
} from '@/modules/common/constants/precision';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import {
  decimal,
  integer,
  maxValue,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import i18n from '@/localisation/i18n';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import SettlementTypeSelect from '@/modules/common/components/SettlementTypeSelect.vue';
import { Api } from '@/modules/common/types/api';
import { Security } from '@/modules/common/models';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import MarketplaceOrderSummary from '@/modules/marketplace/components/MarketplaceOrderSummary.vue';
import {
  commonInForceOptions,
  marketTimeInForceOptions,
  orderTypeOptions,
  timeInForceOptions,
} from '@/modules/marketplace/helpers/marketplace';
import {
  OrderSide,
  OrderType,
  TimeInForceOptions,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import { LoginState } from '@/store/store';
import { ApiError } from '@/utils/errors';
import { calculateOrderNotional, formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import { ClientConfig } from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import Decimal from 'decimal.js';
import { Watch } from 'vue-property-decorator';
import MultipleChipAgreementSelector from '@/modules/agreements/components/MultipleChipAgreementSelector.vue';
import {
  CreateOmsOrderRequest,
  ModifyOmsOrderRequest,
  OmsHistoryResponse,
  OmsOrder,
} from '@/modules/marketplace/models';
import { AgreementInfo } from '@/modules/agreements/models';
import { Side } from '@/connect';

interface FormErrors {
  apiErrors: string[];
  security: string[];
  quantity: string[];
  rate: string[];
  minQuantity: string[];
  form: string[];
}

const defaultMinQuantity = 100;

@Component({
  props: {
    orderRef: String,
    newOrderSide: String as PropType<OrderSide>,
    newOrderSecurity: Object as PropType<Security>,
  },
  components: {
    MultipleChipAgreementSelector,
    SettlementTypeSelect,
    SimpleSecuritySearch,
    BtnDropdown,
    MarketplaceOrderSummary,
  },
  computed: {
    ...mapState(['marketplaceOrderDetails', 'loginState', 'clientConfig']),
  },
  mixins: [validationMixin],
  validations: function (this: MarketplaceOrderFormDialog) {
    return {
      security: { required, tradable: (value: Security) => !value?.cannotTradeMessage },
      quantity: {
        integer,
        required,
        minValue: minValue(1),
        maxValue: maxValue(999999999),
      },
      rate: {
        decimal,
        required: requiredIf(() => {
          return !this.isMarketOrder;
        }),
        notGreaterThanPrecision: this.isMarketOrder ? {} : notGreaterThanPrecision(RATE_PRECISION),
        minValue: this.isMarketOrder ? {} : minValue(-MAX_RATE),
        maxValue: this.isMarketOrder ? {} : maxValue(MAX_RATE),
      },
      minQuantity: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(this.quantity == null ? 1 : this.quantity - this.filled),
      },
      form: {
        changed: () => {
          return (
            !this.$v.$anyDirty ||
            Object.keys(this.formFields).some((field) => {
              let previousValue = this.order?.[field];
              let newValue = this[field];
              if (field === 'agreements') {
                // edge case: user can add/remove agreements,
                // reordering them only while keeping the same agreements
                previousValue = previousValue.sort((a, b) => a.companyId - b.companyId);
                newValue = newValue.sort((a, b) => a.companyId - b.companyId);
              }
              return JSON.stringify(previousValue) !== JSON.stringify(newValue);
            })
          );
        },
      },
    };
  },
})
export default class MarketplaceOrderFormDialog extends mixins(validationMixin) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Side = Side;

  // props
  protected readonly clientConfig!: ClientConfig;
  protected readonly orderRef?: string;
  protected readonly newOrderSide?: 'BORROWER' | 'LENDER';
  protected readonly newOrderSecurity?: Security;

  // consts
  protected readonly defaultMinQuantity = defaultMinQuantity;

  // store state
  protected marketplaceOrderDetails!: OmsHistoryResponse;

  // store methods
  protected loginState!: NonNullableAll<LoginState>;

  protected counterpartyOptions: AgreementInfo[] = [];

  protected side: OrderSide = 'BORROWER';
  protected security: Security | null = null;
  protected formStatus: DialogFormStatus = 'idle';
  protected showSummary = false;
  protected apiErrors: string[] = [];
  protected orderType: OrderType = 'LIMIT';
  protected timeInForceType: TimeInForceType = 'DAY';
  protected rate: Decimal | null = null;
  protected ratePrecision = RATE_PRECISION;
  protected maxIa = MAX_IA;
  protected iaPrecision = IA_PRECISION;
  protected quantity: number | null = null;
  protected minQuantity: number | null = defaultMinQuantity;
  protected filled = 0;
  protected agreements: AgreementInfo[] = [];
  protected isAnonymous = false;
  protected expandedPanel: number | null = null;

  protected minQuantityTouched = false;

  protected orderTypeOptions = orderTypeOptions;
  protected timeInForceOptions = timeInForceOptions;

  protected order: OmsOrder | null = null;
  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'completed';
  protected fetchError = '';
  protected abortController: AbortController | null = null;

  protected get isEditMode(): boolean {
    return !!this.orderRef;
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      security: [],
      quantity: [],
      rate: [],
      minQuantity: [],
      form: [],
    };

    if (!this.$v.form.changed) {
      errors.form.push('Order is already in the requested state.');
    }

    if (this.$v.security.$dirty) {
      if (!this.$v.security.required) errors.security.push('please select a security.');
      if (!this.$v.security.tradable)
        errors.security.push(i18n.t(this.security!.cannotTradeMessage!) as string);
    }

    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
      if (!this.$v.quantity.maxValue)
        errors.quantity.push('must equal or be less than 999,999,999.');
      if (this.filled > 0 && this.quantity && this.quantity <= this.filled)
        errors.quantity.push('must be greater than the executed quantity');
    }

    if (this.$v.rate.$dirty) {
      if (!this.$v.rate.required && (this.orderType === 'LIMIT' || this.orderType === 'IOI'))
        errors.rate.push('rate is required.');
      if (this.rate && !this.$v.rate.notGreaterThanPrecision)
        errors.rate.push(`rate must not be more than ${RATE_PRECISION} decimal places.`);
      if (!this.$v.rate.minValue) {
        errors.rate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}%.`);
      }
      if (!this.$v.rate.maxValue) {
        errors.rate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}%.`);
      }
    }

    if (this.$v.minQuantity.$dirty) {
      if (!this.$v.minQuantity.integer)
        errors.minQuantity.push('please enter a valid min. quantity.');
      if (!this.$v.minQuantity.minValue)
        errors.minQuantity.push('must be greater than or equal to 0.');
      if (this.quantity && !this.$v.minQuantity.maxValue)
        errors.minQuantity.push(
          `must be less than or equal to remaining quantity (${this.prettyNumber(
            this.quantity - this.filled
          )}).`
        );
    }

    return errors;
  }

  protected get securityDescription(): string {
    return this.security ? `${this.security.ticker} [${this.security.cusip}]` : '';
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get formFields(): Omit<
    OmsOrder,
    | 'orderRef'
    | 'status'
    | 'createdAt'
    | 'routingStatus'
    | 'avgExecutionRate'
    | 'openQuantity'
    | 'totalValue'
    | 'company'
    | 'updatedAt'
  > {
    return {
      side: this.side,
      quantity: this.quantity as number,
      minQuantity: this.minQuantity === null ? defaultMinQuantity : this.minQuantity,
      filled: this.filled,
      security: this.security as Security,
      rate: this.rate as Decimal,
      orderType: this.orderType,
      timeInForceType: this.timeInForceType,
      agreements: this.agreements,
      isAnonymous: this.isAnonymous,
    };
  }

  protected get isMarketOrder(): boolean {
    return this.orderType === 'MARKET';
  }

  protected get dependentTimeInForceOptions(): TimeInForceOptions {
    if (this.orderType === 'IOI') {
      return commonInForceOptions;
    }
    if (this.isMarketOrder) {
      return marketTimeInForceOptions;
    }
    return timeInForceOptions;
  }

  protected get filteredOrderTypeOptions(): Array<{ value: OrderType; text: string }> {
    return this.clientConfig.omsAllowMarketOrders
      ? orderTypeOptions
      : orderTypeOptions.filter((option) => option.value !== 'MARKET');
  }

  @Watch('minQuantity')
  protected onMinQuantityChanged(): void {
    if (!this.quantity || !this.minQuantity) return;

    // the moment the minQuantity is changed to something that isn't the quantity or the default
    // it must be that the user manually touched the field
    if (this.minQuantity !== this.quantity && this.minQuantity !== defaultMinQuantity) {
      this.minQuantityTouched = true;
    }
  }

  @Watch('quantity')
  protected onQuantityChanged(): void {
    if (!this.quantity) return;

    // as long as the min quantity has not been touched by the user we make it follow the quantity (up to max 100)
    if (!this.minQuantityTouched) {
      this.minQuantity = Math.min(this.quantity, defaultMinQuantity);
    }
  }

  protected onOrderTypeChanged(): void {
    if (this.orderType === 'MARKET') {
      this.rate = null;
      this.timeInForceType = 'IMMEDIATE_OR_CANCEL';
    } else {
      this.timeInForceType = 'DAY';
    }
  }

  protected created(): void {
    if (this.orderRef) {
      // Pull data from current order (edit) to be used inside the form
      void this.fetchOrder();
      return;
    }

    if (!this.order) {
      // We don't have an order yet (create mode)
      if (this.newOrderSide) {
        this.side = this.newOrderSide;
      }
      if (this.newOrderSecurity) {
        this.security = this.newOrderSecurity;
      }
      this.agreements = [];
      return;
    }

    // We don't have an order yet (create mode)
    if (this.newOrderSide) {
      this.side = this.newOrderSide;
    }
    if (this.newOrderSecurity) {
      this.security = this.newOrderSecurity;
    }
    this.agreements = [];
  }

  protected async fetchOrder(): Promise<void> {
    this.fetchStatus = 'fetching';

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const response = await this.$api.marketplace.fetchOrderDetails(
        this.orderRef as string,
        this.abortController.signal
      );

      if (!response) return;

      this.order = response.order;
      this.fetchStatus = 'completed';
      this.initializeFormFromOrder();
      this.$nextTick(() => {
        // start fresh so "changed" validation works correctly
        this.$v.$reset();
      });
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [errorMessage];
      this.formStatus = 'idle';
      this.fetchStatus = 'failed';
      this.fetchError = errorMessage;
    }
  }

  protected initializeFormFromOrder(): void {
    if (!this.order) return;

    this.side = this.order.side;
    this.quantity = this.order.quantity;
    this.minQuantity = this.order.minQuantity;
    this.filled = this.order.filled;
    this.security = this.order.security;
    this.rate = this.order.rate;
    this.orderType = this.order.orderType;
    this.timeInForceType = this.order.timeInForceType;
    this.agreements = this.order.agreements || [];
    this.isAnonymous = this.order.isAnonymous;

    if (this.minQuantity > defaultMinQuantity) {
      this.minQuantityTouched = true;
    } else {
      this.minQuantityTouched = this.quantity !== this.minQuantity;
    }
  }

  protected async submitForm(routingStatus: OmsOrder['routingStatus']): Promise<void> {
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      if (this.order) {
        await this.$api.marketplace.editOrder(
          ModifyOmsOrderRequest.fromModel({
            orderRef: this.order.orderRef,
            ...this.pickCommonFormFields(routingStatus),
          })
        );

        this.$emit('action', {
          orderRef: this.order.orderRef,
          message: 'Order successfully edited',
          // only show button if OrderDetailsDialog is closed
        });
      } else {
        const { orderRef } = await this.$api.marketplace.createOrder(
          CreateOmsOrderRequest.fromModel({
            side: this.formFields.side,
            security: this.formFields.security,
            ...this.pickCommonFormFields(routingStatus),
          })
        );

        this.$emit('action', {
          orderRef: orderRef,
          message: 'Order successfully created',
        });
      }

      await this.closeDialog();
      this.$emit('success');
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [`Operation Failed: ${errorMessage}`];
      this.formStatus = 'idle';
    }
  }

  protected pickCommonFormFields(
    routingStatus: OmsOrder['routingStatus']
  ): Omit<Api.Marketplace.OrderCommonFormRequest, 'openQuantity' | 'totalValue' | 'updatedAt'> {
    return {
      routingStatus,
      rate: this.formFields.rate,
      quantity: this.formFields.quantity,
      minQuantity: this.formFields.minQuantity,
      agreementIds: (this.formFields.agreements || []).map((c) => c.id),
      orderType: this.formFields.orderType,
      timeInForceType: this.formFields.timeInForceType,
      isAnonymous: this.formFields.isAnonymous,
    };
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  protected goToSummary(): void {
    if (!this.validateForm()) {
      if (this.$v.minQuantity.$invalid) {
        // minQuantity is hidden because "advanced fields" is not expanded by default
        // the other "advanced fields" won't become invalid because
        // they are either required or have a default value
        this.expandedPanel = 0;
      }
      return;
    }

    // get price from passed order prop if edit mode
    // or from selected security if create mode
    const lastClosePrice = this.order
      ? this.order.security.lastClosePrice
      : this.security
        ? this.security.lastClosePrice
        : null;

    const notional = calculateOrderNotional(this.quantity, lastClosePrice);

    // will stop workflow and display soft/hard limits dialog if needed
    new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
      notional,
      () => (this.showSummary = true)
    );
  }

  protected goBack(): void {
    this.showSummary = false;
    this.$v.$reset();
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected prettyNumber(value: number): string {
    return formatPrettyNumber(value);
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }
}
</script>

<style lang="scss" scoped>
.col-6 + .col-6 {
  padding-left: 12px;
}

::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
