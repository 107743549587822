<!--
  Wraps <v-text-field> enabling masked numeric input.
  Automatically adds decimals and thousands separators as the user types.

  Expects a v-model value of type number (integer) or Decimal.

  Property "type" dictates how v-model bahaves:

    - type="integer" (default value)
      - emits and expects a type "number" (integer)
      - precision is always 0 (prop is ignored if passed when integer)

    - type="decimal"
      - emits and expects a type "Decimal"
      - precision can be a fixed value or a range (default is {max: 16}):
          - :precison="2"                   -> always 2 decimal places
          - :precision="{min: 4}"           -> between 4 and 16 decimal places
          - :precision="{min: 2, max: 4}"   -> between 2 and 4 decimal places
          - :precision="{max: 16}"          -> between 0 and 16 decimal places

  Renders an input of type="text" (because of the decimal and thousands separators).
  Accepts "step" and "min" properties, mimicking type="number" behavior.

  ---
  Old vue-currency-input version is used (1.22.6)
    - 2.xx (with Vue 2.6) requires https://github.com/vuejs/composition-api
    - 3.xx requires using Vue 2.7 or Vue 3

  Version 1 docs: https://vue-currency-input-v1.netlify.app/guide/
-->
<template>
  <v-text-field
    ref="inputReference"
    v-model="localFormattedValue"
    v-currency="directiveOptions"
    :append-icon="appendIcon"
    :autofocus="autofocus"
    :class="cssClass"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hint"
    :label="label"
    :max="max"
    :messages="messages"
    :min="min"
    :outlined="outlined"
    :placeholder="placeholder"
    :prefix="prefix"
    :rules="rules"
    :step="step"
    :suffix="suffix"
    type="text"
    @blur="$emit('blur')"
    @change="emitValue('change', $event)"
    @focus="$emit('focus')"
    @input="emitValue('input', $event)"
    @keydown="onKeyDown"
  >
    <!-- Forward slots -->
    <template v-for="(index, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { CurrencyInputOptions, parse, setValue } from 'vue-currency-input';
import Decimal from 'decimal.js';
import wait from '@/modules/common/services/wait';

// Override vue-currency-input defaults
const DEFAULT_DIRECTIVE_OPTIONS: CurrencyInputOptions = {
  // disractionFree === false -> number is formatted as user types
  distractionFree: false,
  locale: 'en-US',
  currency: {},
};

const DEFAULT_INTEGER_PRECISION = 0;
const DEFAULT_DECIMAL_MAX_PRECISION = 16;

type Precision = number | { min?: number; max: number };
type InputValue = number | Decimal | null;

@Component({
  props: {
    value: [Number, Decimal],
    type: { type: String, default: 'integer' },
    precision: [Number, Object],
    rounding: { type: Number, default: Decimal.ROUND_DOWN },
    min: Number,
    max: Number,
    step: { type: Number, default: 1 },
    label: String,
    placeholder: String,
    hint: String,
    prefix: String,
    suffix: String,
    autofocus: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    appendIcon: String,
    messages: {},
    errorMessages: {},
    small: Boolean,
    rules: Array,
  },
})
export default class NumericInput extends Vue {
  public $refs!: {
    inputReference: HTMLInputElement;
  };

  protected readonly type!: 'integer' | 'decimal';
  protected readonly value!: InputValue;
  protected readonly rounding!: Decimal.Rounding;
  protected readonly precision?: Precision;
  protected readonly step!: number;
  protected readonly min!: number;
  protected readonly max?: number;
  protected readonly label?: string;
  protected readonly placeholder?: string;
  protected readonly hint?: string;
  protected readonly prefix?: string;
  protected readonly suffix?: string;
  protected readonly autofocus?: boolean;
  protected readonly clearable?: boolean;
  protected readonly disabled?: boolean;
  protected readonly dense?: boolean;
  protected readonly outlined?: boolean;
  protected readonly errorMessages?: string[];
  protected readonly small?: boolean;
  protected readonly rules?: Array<string | boolean | (() => string | boolean)>;

  // vue-currency-input always emits a formatted string to v-model
  protected localFormattedValue = '';

  /**
   * Inject defaults and return
   */
  protected get normalizedPrecision(): Precision {
    if (typeof this.precision === 'undefined') {
      // default decimal precision allows a range of decimal points
      return this.type === 'decimal'
        ? { max: DEFAULT_DECIMAL_MAX_PRECISION }
        : DEFAULT_INTEGER_PRECISION;
    }

    if (typeof this.precision === 'object') {
      return {
        ...{ max: this.precision.max },
        ...this.precision,
      };
    }

    return this.precision;
  }

  /*
   * Merge optional property precision with DEFAULT_DIRECTIVE_OPTIONS
   */
  protected get directiveOptions(): CurrencyInputOptions {
    return {
      ...DEFAULT_DIRECTIVE_OPTIONS,
      precision: this.normalizedPrecision,
    };
  }

  protected get cssClass(): string {
    return this.small ? 'small' : '';
  }

  /*
   * When a new value is set, convert to number (if Decimal) and compare with the old value
   * Set only when it's different to avoid infinite event loops
   */
  @Watch('value')
  protected onValue(newValue: InputValue, oldValue: InputValue): void {
    // only call setValue if the new value is different from old
    if (this.areEqual(newValue, oldValue)) {
      return;
    }

    // do rounding if newValue is not null
    if (newValue !== null) {
      const precision =
        // only need the value here
        // get from max prop if it's an object (or from the value itself)
        typeof this.normalizedPrecision === 'object'
          ? this.normalizedPrecision.max
          : this.normalizedPrecision;

      newValue = new Decimal(newValue).toDecimalPlaces(precision, this.rounding).toNumber();
    }

    this.setValue(newValue);
  }

  public setFocus(): void {
    // set focus after giving the element time to get enabled
    void wait(250).then(() => {
      this.$refs.inputReference?.focus();
    });
  }

  protected mounted(): void {
    if (typeof this.value === 'undefined') {
      throw new Error(
        'Component requires a v-model of type null | number | Decimal. Did you forget to pass it?'
      );
    } else {
      this.onValue(this.value, null);
    }
  }

  /*
   * Emit numeric value to the parent component
   * Use type to decide if it should emit a Decimal or a number
   */
  protected emitValue(eventName: 'input' | 'change', formattedValue: string): void {
    const currentValue = this.getValue(formattedValue);

    const valueToEmit: InputValue =
      currentValue !== null && this.type === 'decimal' ? new Decimal(currentValue) : currentValue;

    this.$emit(eventName, valueToEmit);
  }

  /*
   * Trigger step value modifications via the keyboard
   * preventDefault avoids cursor moving to start/end of line
   */
  protected onKeyDown(ev: KeyboardEvent): void {
    if (ev.key === 'ArrowUp') {
      ev.preventDefault();
      this.addToValue(this.step);
    }
    if (ev.key === 'ArrowDown') {
      ev.preventDefault();
      this.addToValue(-this.step);
    }
  }

  private areEqual(newValue: InputValue, oldValue: InputValue): boolean {
    if (newValue !== null && oldValue !== null) {
      // new value and old value are number like
      // check their equality using Decimal.equals()
      return new Decimal(newValue).equals(oldValue);
    } else {
      // newValue and oldValue could be number like or null
      return newValue === null && oldValue === null;
    }
  }

  /*
   * Set numeric value in the reference:
   * 1. the v-currency directive formats number to string
   * 2. v-model sets this.formattedValue
   */
  private setValue(value: number | null): void {
    setValue(this.$refs.inputReference, value);
  }

  /*
   * Parse formattedValue back to a number
   */
  private getValue(formattedValue: string): number | null {
    return parse(formattedValue, this.directiveOptions);
  }

  /*
   * Add (or subtract) a value
   */
  private addToValue(diff: number): void {
    const currentValue = this.getValue(this.localFormattedValue) || 0;

    // Add diff to currentValue
    let newValue =
      this.type === 'decimal'
        ? new Decimal(currentValue).add(diff).toNumber()
        : currentValue + diff;

    if (newValue < this.min) {
      newValue = this.min;
    } else if (this.max !== undefined && newValue > this.max) {
      newValue = this.max;
    }

    // when decreasing, we first try to round down to the nearest step;
    // not needed when increasing because we want the user to reach max
    // (even it it's not a multiple of step)
    if (diff < 0) {
      if (this.type === 'decimal') {
        // round to the nearest step
        newValue = new Decimal(newValue).toNearest(this.step, this.rounding).toNumber();
      } else {
        const remainder = newValue % this.step;
        if (remainder !== 0) {
          newValue = this.step + (newValue - remainder);
        }
      }
    }

    this.setValue(newValue);
  }
}
</script>
