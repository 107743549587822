import { AgreementInfo, RawAgreementInfo } from '@/modules/agreements/models';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import {
  OrderEventType,
  OrderSide,
  OrderStatus,
  OrderType,
  RoutingStatus,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import Decimal from 'decimal.js';

export type RawOmsHistoryResponse = Raw<
  OmsHistoryResponse,
  {
    // always specify existing raw entry types explititly
    orderRef: string;
    equity: RawSecurity;
    side: OrderSide;
    createdAt: string;
    updatedAt: string;
    items: Array<RawOmsHistoryItem | RawOmsHistoryExecutedItem>;
    company: RawCompanyInfo | null;
  },
  'order' | 'history'
>;

export class OmsHistoryResponse {
  public order: OmsOrder;
  public history: Array<OmsHistoryItem | OmsHistoryExecutedItem>;
  public company: CompanyInfo | null;

  protected constructor(data: RawOmsHistoryResponse) {
    const rawHistoryItemsReversed = data.items.reverse();
    this.history = rawHistoryItemsReversed.map<OmsHistoryItem | OmsHistoryExecutedItem>((item) =>
      'exec' in item
        ? OmsHistoryExecutedItem.fromData(item, data.equity)
        : OmsHistoryItem.fromData(item, data.equity)
    );
    this.order = OmsOrder.fromData({
      ...data,
      ...rawHistoryItemsReversed[0]?.summary,
    });
    this.company = CompanyInfo.fromData(data.company);
  }

  public static fromData(data: RawOmsHistoryResponse): OmsHistoryResponse {
    return new OmsHistoryResponse(data);
  }

  public static mock(data?: DeepPartial<RawOmsHistoryResponse>): OmsHistoryResponse {
    return OmsHistoryResponse.fromData(OmsHistoryResponse.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawOmsHistoryResponse>): RawOmsHistoryResponse {
    const { equity, items, company, ...rest } = data ?? {};
    return {
      orderRef: 'SLLO202301030000BJ',
      equity: Security.mockData(equity),
      side: 'LENDER',
      createdAt: '2023-01-03T09:50:02.226293Z',
      updatedAt: '2023-01-03T09:50:03.226293Z',
      items:
        items?.map<RawOmsHistoryItem | RawOmsHistoryExecutedItem>((item) =>
          'exec' in item ? OmsHistoryExecutedItem.mockData(item) : OmsHistoryItem.mockData(item)
        ) ?? [],
      company: CompanyInfo.mockData(company),

      ...rest,
    };
  }
}

export type RawOmsOrderSummary = Raw<
  OmsOrderSummary,
  {
    // always specify existing raw entry types explititly
    agreements: RawAgreementInfo[];
  },
  'openQuantity' | 'totalValue'
>;

export class OmsOrderSummary {
  public rate: Decimal | null;
  public quantity: number;
  public minQuantity: number;
  public orderType: OrderType;
  public timeInForceType: TimeInForceType;
  public agreements: AgreementInfo[];
  public isAnonymous: boolean;
  public routingStatus: RoutingStatus;

  public status: OrderStatus;
  public filled: number;
  public avgExecutionRate: Decimal | null;

  public openQuantity: number;
  public totalValue: Decimal;

  protected constructor(data: RawOmsOrderSummary, security: RawSecurity) {
    this.rate = data.rate === null ? null : new Decimal(data.rate);
    this.quantity = data.quantity;
    this.minQuantity = data.minQuantity;
    this.orderType = data.orderType;
    this.timeInForceType = data.timeInForceType;
    this.agreements = data.agreements.map(AgreementInfo.fromData);
    this.isAnonymous = data.isAnonymous;
    this.routingStatus = data.routingStatus;
    this.status = OmsOrderSummary.statusFromData(data.status);
    this.filled = data.filled;
    this.avgExecutionRate =
      data.avgExecutionRate === null ? null : new Decimal(data.avgExecutionRate);

    this.openQuantity = data.quantity - data.filled;
    this.totalValue = new Decimal(this.quantity).times(security.lastClosePrice);
  }

  public static fromData(data: RawOmsOrderSummary, security: RawSecurity): OmsOrderSummary {
    return new OmsOrderSummary(data, security);
  }

  public static mockData(data?: null | DeepPartial<RawOmsOrderSummary>): RawOmsOrderSummary {
    const { agreements, ...rest } = data ?? {};

    return {
      status: 'OPEN',
      rate: '-1',
      avgExecutionRate: null,
      quantity: 20000,
      minQuantity: 1,
      filled: 0,
      routingStatus: 'ROUTED',
      agreements: agreements?.map(AgreementInfo.mockData) ?? [],
      orderType: 'LIMIT',
      timeInForceType: 'GOOD_TILL_CANCEL',
      isAnonymous: false,

      ...rest,
    };
  }

  /**
   * some status are renamed when mapped to OrderStatus
   */
  private static statusFromData(status: string) {
    switch (status) {
      case 'FILLED':
        return 'FILLED';
      case 'CANCELED':
        return 'CANCELED';
      case 'EXPIRED':
        return 'EXPIRED';
      case 'TERMINATED':
        return 'TERMINATED';
      default:
        return 'OPEN';
    }
  }
}

export type RawOmsOrder = Raw<
  OmsOrder,
  {
    // always specify existing raw entry types explititly
    agreements: RawAgreementInfo[];
    equity: RawSecurity;
    company: RawCompanyInfo | null;
  },
  'security' | 'openQuantity' | 'totalValue'
>;

export class OmsOrder extends OmsOrderSummary {
  public orderRef: string;
  public security: Security;
  public createdAt: Date;
  public updatedAt: Date;
  public side: OrderSide;
  public company: CompanyInfo | null;

  protected constructor(data: RawOmsOrder) {
    super(data, data.equity);

    this.orderRef = data.orderRef;
    this.security = Security.fromData(data.equity);
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.side = data.side;
    this.company = CompanyInfo.fromData(data.company);
  }

  public static fromData(data: RawOmsOrder): OmsOrder {
    return new OmsOrder(data);
  }

  public static mockData(data?: DeepPartial<RawOmsOrder>): RawOmsOrder {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { agreements: _agreements, equity, company, ...rest } = data ?? {};
    return {
      ...OmsOrderSummary.mockData(data),
      orderRef: 'SLLO202301030000BJ',
      equity: Security.mockData(equity),
      createdAt: '2023-01-03T09:50:02.226293Z',
      updatedAt: '2023-01-03T09:50:03.226293Z',
      side: 'LENDER',
      company: CompanyInfo.mockData(company),

      ...rest,
    };
  }
}

export type RawOmsHistoryItem = Raw<
  OmsHistoryItem,
  {
    // always specify existing raw entry types explititly
    eventTimestamp: string;
    initiator: RawEventInitiator;
    summary: RawOmsOrderSummary;
  }
>;

export class OmsHistoryItem {
  public eventType: OrderEventType;
  public eventIndex: number;
  public eventTimestamp: Date;
  public initiator: EventInitiator;
  public summary: OmsOrderSummary;

  protected constructor(data: RawOmsHistoryItem, security: RawSecurity) {
    this.eventType = data.eventType;
    this.eventIndex = data.eventIndex;
    this.eventTimestamp = new Date(data.eventTimestamp);
    this.initiator = EventInitiator.fromData(data.initiator);
    this.summary = OmsOrderSummary.fromData(data.summary, security);
  }

  public static fromData(data: RawOmsHistoryItem, security: RawSecurity): OmsHistoryItem {
    return new OmsHistoryItem(data, security);
  }

  public static mockData(data?: null | DeepPartial<RawOmsHistoryItem>): RawOmsHistoryItem {
    const { initiator, summary, ...rest } = data ?? {};

    return {
      eventType: 'CREATED',
      eventIndex: 0,
      eventTimestamp: '2023-02-20T12:47:24.625423717Z',
      initiator: EventInitiator.mockData(initiator),
      summary: OmsOrderSummary.mockData(summary),

      ...rest,
    };
  }
}

export type RawEventInitiator = Raw<EventInitiator>;

export class EventInitiator {
  public kind: 'CONTRA' | 'SYSTEM' | 'TRADER' | 'BOT_USER' | 'UNKNOWN';
  public email: string | null;
  public externalIdentifier: string | null;

  protected constructor(data: RawEventInitiator) {
    this.kind = data.kind;
    this.email = data.email;
    this.externalIdentifier = data.externalIdentifier;
  }

  public static fromData(data: RawEventInitiator): EventInitiator {
    return new EventInitiator(data);
  }

  public static mockData(data?: null | DeepPartial<RawEventInitiator>): RawEventInitiator {
    return {
      kind: 'SYSTEM',
      email: null,
      externalIdentifier: null,

      ...data,
    };
  }
}

export type RawOmsHistoryExecutedItem = Raw<
  OmsHistoryExecutedItem,
  {
    // always specify existing raw entry types explititly
    eventTimestamp: string;
    initiator: RawEventInitiator;
    summary: RawOmsOrderSummary;
    rate: string;
    independentAmountRate: string;
    unitPrice: string;
    counterparty: RawCompanyInfo;
  }
>;

export class OmsHistoryExecutedItem extends OmsHistoryItem {
  public loanId: string | null;
  public execId: string;
  public quantity: number;
  public rate: Decimal;
  public independentAmountRate: Decimal;
  public unitPrice: Decimal;
  public roundingRule: string;
  public counterparty: CompanyInfo | null;

  protected constructor(data: RawOmsHistoryExecutedItem, security: RawSecurity) {
    super(data, security);

    this.loanId = data.loanId;
    this.execId = data.execId;
    this.quantity = data.quantity;
    this.rate = new Decimal(data.rate);
    this.independentAmountRate = new Decimal(data.independentAmountRate);
    this.unitPrice = new Decimal(data.unitPrice);
    this.roundingRule = data.roundingRule;
    this.counterparty = CompanyInfo.fromData(data.counterparty);
  }
  public static mockData(data?: DeepPartial<RawOmsHistoryExecutedItem>): RawOmsHistoryExecutedItem {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { counterparty, initiator: _initiator, summary: _summary, ...rest } = data ?? {};
    return {
      ...OmsHistoryItem.mockData(data),
      loanId: '0',
      execId: 'SL0L202302200000GC',
      quantity: 7000,
      rate: '-1',
      independentAmountRate: '0',
      unitPrice: '258.06',
      roundingRule: 'NO',
      counterparty: CompanyInfo.mockData(counterparty),

      ...rest,
    };
  }
}
