import i18n from '@/localisation/i18n';
import logger from '@/modules/common/services/logger.service';
import { AppState, store } from '@/store/store';
import { useStoreAuth } from '@/store/store-auth';
import { PermissionError } from '@/utils/errors/permission-error';
import { TradingPermission, hasOneOfTradingPermission } from '@/utils/helpers/trading-permissions';
import { AxiosError } from 'axios';
import { uniq } from 'lodash';
import Router, { Location, NavigationGuardNext, RawLocation, Route, RouteRecord } from 'vue-router';
import { ErrorHandler } from 'vue-router/types/router';
import { Store } from 'vuex';
import { routes } from './routes';

const router = new Router({
  mode: 'hash', // history or hash
  routes,
});

// fix router redirect complaints
const replace = Router.prototype.replace;
Router.prototype.replace = (
  location: RawLocation,
  onComplete?: (route: Route) => void | undefined,
  onAbort?: ErrorHandler | undefined
) =>
  new Promise<Route>((resolve, reject) =>
    replace.bind(router)(
      location,
      (route: Route) => (onComplete?.(route), resolve(route)),
      onAbort ??
        ((e) => {
          if (`${e}`.startsWith('Error: Redirected')) {
            resolve(router.match(location, router.currentRoute));
            return;
          }
          reject(e);
        })
    )
  );

function routeRequiresAuth(route: Route | RouteRecord): number {
  if (typeof route.meta?.requiresAuth !== 'undefined') {
    return route.meta.requiresAuth;
  } else if ('matched' in route) {
    // find the highest auth requirement
    return route.matched.reduce((max, m) => {
      const required = routeRequiresAuth(m);
      return required > max ? required : max;
    }, 0);
  } else {
    return 0;
  }
}

/**
 * get all the permissions required to access to this route (and its parents)
 * @param route
 */
function getRouteRequiredPermissions(route: Route | RouteRecord): TradingPermission[] {
  return uniq(
    [
      // add the permissions required for this route
      typeof route.meta?.requiresPerm !== 'undefined' ? route.meta?.requiresPerm : 0,
      // and add any permissions required for parent routes
      ...('matched' in route ? route.matched.map((r) => getRouteRequiredPermissions(r)) : []),
    ].filter((p) => p != 0)
  );
}

/**
 * Provides type narrowing for an unknown value to a permission error
 */
function isAuthError(err: unknown | AxiosError): err is PermissionError | AxiosError {
  if (err instanceof PermissionError) {
    return true;
  } else if (err instanceof Error && 'response' in err) {
    return (
      (err as AxiosError).response?.status === 401 || (err as AxiosError).response?.status === 403
    );
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  await beforePageChange(store, to, from, next);
});

/**
 * Navigation guard for route authtication checks
 * export 'beforeEach' so that we can test it
 *
 * @TODO should really have tests for this...
 */
export async function beforePageChange(
  st: Store<AppState>,
  to: Route,
  _from: Route,
  next: NavigationGuardNext
): Promise<void> {
  // Loading auth state
  const storeAuth = useStoreAuth();

  // check the route's auth requirements
  const requiredAuthLevel = routeRequiresAuth(to);

  try {
    // fetch currently logged in user; throws an exception if not logged on
    if (!st.state.loginState.user && storeAuth.isUserAuthenticated) {
      await st.dispatch('fetchCurrentUser');
    }

    // protected route
    if (requiredAuthLevel > 0) {
      // check permissions
      const validator = st.state.loginState.permissionValidator;
      if (!validator || !validator.hasPermission(requiredAuthLevel)) {
        // if users have no permission to view this protected page
        // redirect them to the login page to get the required authorization
        const location: Location = { name: 'login', replace: true };
        if (to.redirectedFrom !== '/') {
          location.params = { error: i18n.tc('loginGetAuthorized') };
        }
        next(location);
        return;
      }

      // collect required permissions for route and its parents (can be different sets of permissions)
      const requiredPermissions = getRouteRequiredPermissions(to);
      // and check that we satisfy all the different sets of permissions
      const hasRequiredPermissions = requiredPermissions.every((perm) => {
        return hasOneOfTradingPermission(st.state.loginState, perm);
      }, true);
      if (hasRequiredPermissions) {
        // permission granted, show protected page
        next();
      } else {
        // permission denied, redirect to the trader's home page
        next({ name: 'dashboard.trader' });
      }
    } else {
      // show public page
      next();
    }
  } catch (err) {
    if (requiredAuthLevel <= 0) {
      // continue to public route; no auth required
      return next();
    } else if (isAuthError(err)) {
      // permission error thrown before a protected route; redirect to login
      // clear all user related data!
      try {
        await storeAuth.logout();
      } catch {
        // critical error. Go to error page to avoid redirection loops
        logger.error(`failed to logout:`, err);
        next({ name: 'error-500' });
        return;
      }

      // re-login
      const query = st.state.loginState.user
        ? { emailAddress: st.state.loginState.user.emailAddress }
        : {};
      next({ name: 'login', query });
    } else {
      // some other error. Go to appropriate error page
      logger.error(err);
      next({ name: 'error-500' });
    }
  }
}

// This callback runs before every route change, including on page load.
router.beforeEach((_to, _from, next) => {
  if (store.state.clientConfig) {
    const { systemTitleLong, systemEnv } = store.state.clientConfig;
    document.title =
      systemEnv === 'prod' ? systemTitleLong : `${systemTitleLong} [${systemEnv.toUpperCase()}]`;
  }

  next();
});

export default router;

export function useRouter(): Router {
  return router;
}

export function useRoute(): Route {
  return router.currentRoute;
}
