<template>
  <ag-table-server
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :is-row-selectable="isRowSelectable"
    :page="1"
    :page-size="1000"
    :query-data="queryData"
    :selected-items="selectedItems"
    :sort="sort"
    table-id="MarketplaceOrdersTable"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ColDef, RowNode, SortModelItem } from 'ag-grid-enterprise';
import { AgTableServer } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace';
import { Order, QueryOrdersResponse } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { OmsOrderStatus } from '@/connect/gen/consts/omsconsts_pb';

const allActions = ['view', 'edit', 'route', 'unroute', 'cancel'];

const counterpartyChoiceEnabledCols = ['counterparties'];
const settlementTypeChoiceEnabledCols = ['settlementType'];

@Component({
  components: {
    AgTableServer,
  },
  props: {
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    actions: {
      type: Array as PropType<string[]>,
      default: () => allActions,
    },
    queryData: {
      type: Function,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<Order[]>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    sort: {
      type: Object as PropType<SortModelItem>,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class MarketplaceOrdersTable extends Vue {
  // props
  protected readonly omitHeaders!: string[];
  protected readonly actions!: string[];
  protected readonly queryData!: () => Promise<QueryOrdersResponse>;
  protected readonly selectedItems!: Order[];
  protected readonly showSelect!: boolean;
  protected readonly sort!: SortModelItem;

  // store states
  protected clientConfig!: ClientConfig;

  // subset of items currently rendered in the table
  protected currentRenderedItems: Order[] = [];

  protected get columnDefs(): ColDef[] {
    return [
      cols.checkbox(),
      cols.status({
        viewOrder: this.viewOrder,
      }),
      cols.updateTime(),
      cols.side(),
      cols.active(),
      cols.company(),
      cols.ticker(),
      cols.cusip(),
      cols.openQuantity(),
      cols.rate(),
      cols.execQty(),
      cols.avgExecRate(),
      cols.totalQuantity(),
      cols.createTime(),
      cols.orderType(),
      cols.timeInForce(),
      cols.minQuantity(),
      cols.agreements(),
      cols.settlementType(),
      cols.orderRef(),
      cols.actions({
        actions: this.actions,
        viewOrder: this.viewOrder,
        editOrder: (order: Order) => this.$emit('edit-order', order),
      }),
    ].filter(
      (colDef) =>
        (this.showSelect || colDef.colId !== 'checkbox') &&
        !this.omitHeaders.includes(colDef.field as string) &&
        (this.clientConfig.orderbookCounterpartyChoiceEnabled ||
          !counterpartyChoiceEnabledCols.includes(colDef.field as string)) &&
        (this.clientConfig.orderbookSettlementTypeChoiceEnabled ||
          !settlementTypeChoiceEnabledCols.includes(colDef.field as string))
    );
  }

  public viewOrder(orderRef: string): void {
    this.$emit('view-order', orderRef);
  }

  protected getRowId(order: Order): string {
    return order.orderRef;
  }

  protected isRowSelectable(rowNode: RowNode): boolean {
    return !this.isStatusDisabled(rowNode.data.orderStatus);
  }

  // if the order status is one of the fixed array items return true
  // in the template the checkbox :disabled will be true
  protected isStatusDisabled(status: OmsOrderStatus): boolean {
    const disabledStatuses: OmsOrderStatus[] = [
      OmsOrderStatus.CANCELED,
      OmsOrderStatus.EXPIRED,
      OmsOrderStatus.TERMINATED,
      OmsOrderStatus.FILLED,
    ];

    return disabledStatuses.includes(status);
  }

  protected filterOpenItems(items: Order[]): Order[] {
    return items.filter(
      (item) =>
        item.orderStatus !== OmsOrderStatus.FILLED && item.orderStatus !== OmsOrderStatus.CANCELED
    );
  }
}
</script>
