import { Raw } from '@/modules/common/helpers/api';
import {
  OrderType,
  RoutingStatus,
  SettlementType,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import Decimal from 'decimal.js';

export type RawOmsOrderRequestDetailsOptional = Raw<OmsOrderRequestDetailsOptional>;

class OmsOrderRequestDetailsOptional {
  public settlementType: SettlementType | null;
  public quantity: number | null;
  public minQuantity: number | null;
  public rate: Decimal | null;
  public agreementIds: string[] | null;
  public orderType: OrderType | null;
  public timeInForceType: TimeInForceType | null;
  public roundingRule: RoundingRule | null;
  public independentAmountRate: Decimal | null;
  public isAnonymous: boolean | null;
  public routingStatus: RoutingStatus | null;

  protected constructor(data: OmsOrderRequestDetailsOptional) {
    this.settlementType = data.settlementType;
    this.quantity = data.quantity;
    this.minQuantity = data.minQuantity;
    this.rate = data.rate;
    this.agreementIds = data.agreementIds;
    this.orderType = data.orderType;
    this.timeInForceType = data.timeInForceType;
    this.roundingRule = data.roundingRule;
    this.independentAmountRate = data.independentAmountRate;
    this.isAnonymous = data.isAnonymous;
    this.routingStatus = data.routingStatus;
  }

  public static toData(model: OmsOrderRequestDetailsOptional): RawOmsOrderRequestDetailsOptional {
    return {
      settlementType: model.settlementType,
      quantity: model.quantity,
      minQuantity: model.minQuantity,
      rate: model.rate?.toString() ?? null,
      agreementIds: model.agreementIds,
      orderType: model.orderType,
      timeInForceType: model.timeInForceType,
      roundingRule: model.roundingRule,
      independentAmountRate: model.independentAmountRate?.toString() ?? null,
      isAnonymous: model.isAnonymous,
      routingStatus: model.routingStatus,
    };
  }
}

export type RawModifyOmsOrdersRequest = Raw<ModifyOmsOrdersRequest>;

export class ModifyOmsOrdersRequest extends OmsOrderRequestDetailsOptional {
  public orderRefs: string[];

  protected constructor(data: ModifyOmsOrdersRequest) {
    super(data);

    this.orderRefs = data.orderRefs;
  }

  public static fromModel(data: ModifyOmsOrdersRequest): ModifyOmsOrdersRequest {
    return new ModifyOmsOrdersRequest(data);
  }

  public static toData(data: ModifyOmsOrdersRequest): RawModifyOmsOrdersRequest {
    return {
      ...OmsOrderRequestDetailsOptional.toData(data),
      orderRefs: data.orderRefs,
    };
  }
}

export type RawModifyOmsOrdersResponse = Raw<ModifyOmsOrdersResponse>;

export class ModifyOmsOrdersResponse {
  public failedOrderRefs: string[];

  protected constructor(data: ModifyOmsOrdersResponse) {
    this.failedOrderRefs = data.failedOrderRefs;
  }

  public static fromData(data: ModifyOmsOrdersResponse): ModifyOmsOrdersResponse {
    return new ModifyOmsOrdersResponse(data);
  }

  public static toData(data: ModifyOmsOrdersResponse): RawModifyOmsOrdersResponse {
    return {
      failedOrderRefs: data.failedOrderRefs,
    };
  }
}
