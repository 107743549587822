import { PbCompanyInfo, Timestamp } from '@/connect';
import { AgreementInfo } from '@/modules/agreements/models';
import { formatDate } from '@/utils/helpers/dates';
import Decimal from 'decimal.js';

export function formatAgreementInfo(agreementInfo?: AgreementInfo): string {
  return agreementInfo === undefined
    ? ''
    : `${agreementInfo.displayId}${agreementInfo.shortName ? ` – ${agreementInfo.shortName}` : ''}`;
}

export function formatCompanyInfo(companyInfo?: PbCompanyInfo): string {
  return companyInfo === undefined
    ? ''
    : companyInfo.displayBoxId
      ? `${companyInfo.displayBoxId} – ${companyInfo.companyName}`
      : companyInfo.companyName;
}

export function formatIndependentAmountRate(independentAmountRate: string): string {
  return new Decimal(independentAmountRate).add(100).toFixed(0) + '%';
}

export function formatTimestampTime(timestamp?: Timestamp | null): string {
  return !timestamp ? '' : formatDate(timestamp.toDate(), 'hh:mm a');
}

export function formatTimestampDate(timestamp?: Timestamp | null): string {
  return !timestamp ? '' : formatDate(timestamp.toDate(), 'MMM dd');
}

export function formatTimestamp(timestamp?: Timestamp | null): string {
  return !timestamp ? '' : `${formatTimestampDate(timestamp)} ${formatTimestampTime(timestamp)}`;
}
