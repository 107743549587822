<!-- eslint-disable @typescript-eslint/unified-signatures -->

<script setup lang="ts">
import { computed, onMounted, shallowRef, watch } from 'vue';
import { AgreementInfo } from '@/modules/agreements/models';
import { formatAgreementInfo } from '@/modules/agreements/utils';
import { AgreementStatus, Side, serviceAgreements } from '@/connect';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import i18n from '@/localisation/i18n';

const props = defineProps<{
  value?: AgreementInfo | null;
  statuses?: AgreementStatus[];
  label?: string;
  autofocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  dense?: boolean;
  errorMessages?: string[];
  placeholder?: string;
  multiple?: boolean;
  prependItem?: AgreementInfo;
  side?: Side;
}>();

const emit = defineEmits<{
  (event: 'input', item: AgreementInfo): void;
  (event: 'change', item: AgreementInfo): void;
  (event: 'blur'): void;
}>();

const snackbarManager = useSnackbarManager();

const isLoading = shallowRef(false);
const items = shallowRef<AgreementInfo[]>([]);
const allItems = computed(() =>
  props.prependItem ? [props.prependItem, ...items.value] : items.value
);

watch(
  () => props.value,
  () => {
    setItemsWithValueAsOnlyItem();
    if (!props.value) items.value = [];
  }
);

// items not loaded yet, set the value as the only item
onMounted(setItemsWithValueAsOnlyItem);

function setItemsWithValueAsOnlyItem(): void {
  if (props.value && !props.multiple) {
    // if we are not in multiple mode, value is a single agreement
    items.value = [props.value];
  }
}

function onItemSelected(item: AgreementInfo): void {
  emit('input', item);
}

function onChange(item: AgreementInfo): void {
  emit('change', item);
}

async function search(query: string): Promise<void> {
  if (!query) {
    // no query, or item in list was selected
    return;
  }

  isLoading.value = true;

  const result = await serviceAgreements.queryAgreements({
    statuses: props.statuses ?? [],
    side: props.side,
  });
  if (result.success) {
    items.value = result.success ? result.data.agreements.map(AgreementInfo.fromData) : [];
  } else {
    items.value = [];
    snackbarManager.error(i18n.t(result.error, result.details) as string);
  }

  isLoading.value = false;
}
</script>

<template>
  <VAutocomplete
    auto-select-first
    :autofocus="autofocus"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    item-value="id"
    :items="allItems"
    :label="label"
    :loading="isLoading"
    no-filter
    :placeholder="placeholder || 'search...'"
    return-object
    :value="value"
    @blur="emit('blur')"
    @change="onChange"
    @input="onItemSelected"
    @update:search-input="search"
  >
    <template #selection="data">
      {{ formatAgreementInfo(data.item) }}
    </template>
    <template #item="data">
      <VListItemContent>
        <VListItemTitle>
          {{ formatAgreementInfo(data.item) }}
        </VListItemTitle>
      </VListItemContent>
    </template>
  </VAutocomplete>
</template>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  white-space: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
