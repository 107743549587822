import logger from '@/modules/common/services/logger.service';
import { ComputedRef, ShallowRef, computed, reactive, ref, shallowRef } from 'vue';

/**
 * Creates a set of rules and handles their validation state.
 *
 * @param isInvalid - Reference to the invalid state of the field.
 * @param isBusy - Reference to the busy (async validation) state of the field.
 * @param rules - Array of validation rules to apply to the field.
 * @param [transform] - Optional transformation function for the value before validation.
 * @returns An object containing the validation rules and error messages.
 */
function getRules<R, T>(
  isInvalid: ShallowRef<boolean>,
  isBusy: ShallowRef<boolean>,
  rules: RulesArray<R, T>,
  transform?: RulesTransform<R, T>
): {
  rules: Array<(value: T) => true | string>;
  errorMessages: string[];
} {
  const errorMessages = ref<string[]>([]);
  const rulesModified = rules.map((rule) => (value: T) => {
    if (transform !== undefined) {
      value = transform(value as unknown as R);
    }
    const result = rule(value as T extends never ? R : T);
    if (result instanceof Promise) {
      // we don't care about async validation of an empty value
      if (value) {
        isBusy.value = true;
        void result
          .then((v) => {
            if (typeof v === 'string') {
              isInvalid.value = true;
              errorMessages.value.push(v);
            }
          })
          .catch((e) => {
            logger.error(e);
            isInvalid.value = true;
            errorMessages.value.push('Validation error');
          })
          .finally(() => (isBusy.value = false));
      }
      // errorMessages will take care of the error state, if any
      return true;
    }
    if (typeof result === 'string') {
      isInvalid.value = true;
      return result;
    }
    return true;
  });
  // reset business, validity and errorMessages first, before any other validation
  rulesModified.unshift(() => {
    isBusy.value = false;
    isInvalid.value = false;
    errorMessages.value = [];
    return true;
  });
  return reactive({
    rules: rulesModified as Array<(value: T) => true | string>,
    errorMessages,
  });
}

type RulesArray<R, T> = Array<
  (value: T extends never ? R : T) => true | string | Promise<true | string>
>;
type RulesTransform<R, T> = T extends never ? undefined : (value: R) => T;
type Rules<R, T> = {
  [K in keyof R]: {
    rules: RulesArray<R[K], K extends keyof T ? T[K] : R[K]>;
  } & (K extends keyof T
    ? {
        transform: (value: R[K]) => T[K];
      }
    : {
        transform?: never;
      });
};

/**
 * Provides reactive validation rules and their states.
 *
 * @param rules - An object containing field rules and optional transformations.
 * @returns Reactive validation state and rules for each field.
 */
export function useRules<R, T = object, O extends Rules<R, T> = Rules<R, T>>(
  rules: O
): {
  isInvalid: ComputedRef<boolean>;
  isValid: ComputedRef<boolean>;
  isBusy: ComputedRef<boolean>;
  rules: {
    [K in keyof R]: {
      rules: Array<(value: T) => true | string>;
      errorMessages: string[];
    };
  };
} {
  const resultingRules = {} as {
    [K in keyof R]: {
      rules: Array<(value: T) => true | string>;
      errorMessages: string[];
    };
  };

  const fieldsInvalidState: Array<ShallowRef<boolean>> = [];
  const fieldsBusyState: Array<ShallowRef<boolean>> = [];

  for (const [field, config] of Object.entries(rules)) {
    const fieldInvalidState = shallowRef(false);
    fieldsInvalidState.push(fieldInvalidState);
    const fieldBusyState = shallowRef(false);
    fieldsBusyState.push(fieldBusyState);

    const typedConfig = config as Rules<R, T>[keyof R];
    resultingRules[field as keyof R] = getRules(
      fieldInvalidState,
      fieldBusyState,
      typedConfig.rules,
      typedConfig.transform as
        | RulesTransform<R[keyof R], keyof R extends keyof T ? T[keyof T & keyof R] : never>
        | undefined
    ) as {
      rules: Array<(value: T) => true | string>;
      errorMessages: string[];
    };
  }

  const isBusy = computed(() => fieldsBusyState.some((fbs) => fbs.value));
  const isInvalid = computed(() => fieldsInvalidState.some((fvs) => fvs.value));
  const isValid = computed(() => !isBusy.value && !isInvalid.value);

  return {
    isBusy,
    isValid,
    isInvalid,
    rules: resultingRules,
  };
}
