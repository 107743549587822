// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/agreements/agreements.proto (package aurora.core.apiengine.agreementsv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ApproveAgreementRequest, ApproveAgreementResponse, CancelAgreementRequest, CancelAgreementResponse, CreateAgreementRequest, CreateAgreementResponse, ModifyAgreementRequest, ModifyAgreementResponse, QueryAgreementsRequest, QueryAgreementsResponse, RejectAgreementRequest, RejectAgreementResponse } from "./agreements_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Methods to create and modify agreements
 *
 * @generated from service aurora.core.apiengine.agreementsv1.AgreementsService
 */
export const AgreementsService = {
  typeName: "aurora.core.apiengine.agreementsv1.AgreementsService",
  methods: {
    /**
     * CreateAgreement can be used to create an agreement
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.CreateAgreement
     */
    createAgreement: {
      name: "CreateAgreement",
      I: CreateAgreementRequest,
      O: CreateAgreementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ApproveAgreement can be used to approve a drafted agreement
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.ApproveAgreement
     */
    approveAgreement: {
      name: "ApproveAgreement",
      I: ApproveAgreementRequest,
      O: ApproveAgreementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RejectAgreement can be used to reject a drafted agreement
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.RejectAgreement
     */
    rejectAgreement: {
      name: "RejectAgreement",
      I: RejectAgreementRequest,
      O: RejectAgreementResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ModifyAgreement can be used to create a new version of an agreement
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.ModifyAgreement
     */
    modifyAgreement: {
      name: "ModifyAgreement",
      I: ModifyAgreementRequest,
      O: ModifyAgreementResponse,
      kind: MethodKind.Unary,
    },
    /**
     *  CancelAgreement can be used to cancel a drafted or active agreement
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.CancelAgreement
     */
    cancelAgreement: {
      name: "CancelAgreement",
      I: CancelAgreementRequest,
      O: CancelAgreementResponse,
      kind: MethodKind.Unary,
    },
    /**
     *  QueryAgreements can be used to query agreements
     *
     * @generated from rpc aurora.core.apiengine.agreementsv1.AgreementsService.QueryAgreements
     */
    queryAgreements: {
      name: "QueryAgreements",
      I: QueryAgreementsRequest,
      O: QueryAgreementsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

