<script setup lang="ts">
import {
  Agreement,
  CreateAgreementRequest,
  ModifyAgreementRequest,
  RoundingRule,
  SettlementType,
  Side,
  roundingRules,
  settlementTypes,
} from '@/connect';
import { UnwrapRef, computed, ref, shallowRef, watchEffect } from 'vue';
import NumericInput from '@/modules/common/components/NumericInput.vue';
import { useStoreAuth } from '@/store/store-auth';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo } from '@/modules/common/models';
import { useRules } from '@/utils/vuetify-validation';
import { IA_PRECISION, MAX_IA } from '@/modules/common/constants/precision';
import Decimal from 'decimal.js';

const props = defineProps<{
  source: Agreement | null;
  value: CreateAgreementRequest | ModifyAgreementRequest | null;
  reservedShortNames: Set<string>;
}>();

const emit = defineEmits<{
  (event: 'cancel'): void;
  (
    event: 'save',
    agreementRequest: UnwrapRef<CreateAgreementRequest | ModifyAgreementRequest>
  ): void;
}>();

const storeAuth = useStoreAuth();

const agreementRequest = ref(props.value);
watchEffect(
  () =>
    (agreementRequest.value =
      props.value instanceof CreateAgreementRequest
        ? new CreateAgreementRequest(props.value)
        : props.value instanceof ModifyAgreementRequest
          ? new ModifyAgreementRequest(props.value)
          : null)
);

const company = computed(() => {
  return props.source?.borrowerCompany?.companyId === storeAuth.user?.companyID
    ? props.source?.lenderCompany ?? null
    : props.source?.lenderCompany?.companyId === storeAuth.user?.companyID
      ? props.source?.borrowerCompany ?? null
      : null;
});

const agreementCounterparty = shallowRef<null | CompanyInfo>(null);
watchEffect(
  () =>
    (agreementCounterparty.value =
      company.value === null
        ? null
        : CompanyInfo.fromData({
            companyId: company.value.companyId,
            displayBoxId: company.value.displayBoxId ?? null,
            companyName: company.value.companyName,
            cutOffTime: null,
          }))
);
watchEffect(() => {
  if (agreementRequest.value instanceof CreateAgreementRequest) {
    agreementRequest.value.counterpartyId = agreementCounterparty.value?.companyId ?? '';
  }
});

function closeDialog() {
  emit('cancel');
}

function save(request: UnwrapRef<CreateAgreementRequest | ModifyAgreementRequest>) {
  emit('save', request);
  closeDialog();
}

const independentAmountRate = computed({
  get: () =>
    agreementRequest.value?.independentAmountRate === undefined
      ? storeAuth.user?.companyPreferredIndependentAmountRate
      : +agreementRequest.value?.independentAmountRate,
  set: (value) =>
    agreementRequest.value &&
    (agreementRequest.value.independentAmountRate = value ? `${value}` : '0'),
});

const roundingRule = computed({
  get: () =>
    agreementRequest.value?.roundingRule ??
    (storeAuth.user?.companyPreferredRoundingRule !== undefined &&
    storeAuth.user.companyPreferredRoundingRule !== null
      ? RoundingRule[storeAuth.user.companyPreferredRoundingRule]
      : RoundingRule.NO_ROUNDING),
  set: (value) => agreementRequest.value && (agreementRequest.value.roundingRule = value),
});

const availableSettlementTypes = [...settlementTypes.entries()].map(([id, name]) => ({ id, name }));
const availableRoundingRules = [...roundingRules.entries()].map(([id, name]) => ({ id, name }));

const sideOptions = [
  { text: 'Lender', value: Side.LENDER },
  { text: 'Borrower', value: Side.BORROWER },
];

function required<T>(value: T) {
  return !!value || 'Required';
}

const { rules, isValid } = useRules<
  {
    shortName: string;
    independentAmountRate: string;
    counterparty: CompanyInfo;
    settlementType: SettlementType;
    priceRoundingRules: RoundingRule;
  },
  {
    independentAmountRate: Decimal | null;
  }
>({
  shortName: {
    rules: [
      required,
      (value) =>
        props.source?.shortName === value ||
        !props.reservedShortNames.has(value.trim()) ||
        'Must be unique',
    ],
  },
  independentAmountRate: {
    // Decimal dislikes commas
    transform: (value) => (!value ? null : new Decimal(value.replaceAll(',', ''))),
    rules: [
      required,
      (value) => value?.gte(0) || `IA Rate cannot be negative.`,
      (value) => value?.lte(MAX_IA) || `IA Rate cannot be more than ${MAX_IA}.`,
      (value) =>
        (value && value.decimalPlaces() <= IA_PRECISION) ||
        `IA Rate must not be more than ${IA_PRECISION} decimal places.`,
    ],
  },
  counterparty: {
    rules: [required],
  },
  settlementType: {
    rules: [required],
  },
  priceRoundingRules: {
    rules: [required],
  },
});
</script>

<template>
  <VDialog
    v-shortkey="['esc']"
    max-width="600"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="agreementRequest"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    @shortkey.native="closeDialog"
  >
    <VForm v-if="agreementRequest" novalidate @submit.prevent>
      <VCard>
        <VBtn class="close-icon" icon @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
        <VCardTitle class="d-flex align-center flex-grow-1 headline">
          {{ agreementRequest instanceof CreateAgreementRequest ? 'New' : 'Edit' }} Agreement
        </VCardTitle>
        <div class="form-grid">
          <VTextField
            v-model="agreementRequest.shortName"
            :autofocus="!agreementRequest.shortName"
            label="Agreement Short Name"
            v-bind="rules.shortName"
          />
          <template v-if="agreementRequest instanceof CreateAgreementRequest">
            <VSelect
              v-model="agreementRequest.side"
              hide-details
              :items="sideOptions"
              label="Side"
              placeholder="Select side"
            />
            <CounterpartySearch
              v-model="agreementCounterparty"
              :bilateral="agreementRequest.settlementType == SettlementType.BILATERAL"
              :exclude-company-id="storeAuth.user?.companyID"
              label="Counterparty"
              placeholder="Select a company"
              :side="Side[1 - agreementRequest.side]"
              v-bind="rules.counterparty"
            />
          </template>
          <VSelect
            v-model="agreementRequest.settlementType"
            item-text="name"
            item-value="id"
            :items="availableSettlementTypes"
            label="Settlement Type"
            placeholder="Select a settlement type"
            v-bind="rules.settlementType"
          />
          <NumericInput
            v-model="independentAmountRate"
            label="Independent Amount"
            v-bind="rules.independentAmountRate"
            :max="MAX_IA"
            :min="0"
            :precision="IA_PRECISION"
            suffix="%"
            type="decimal"
          />
          <VSelect
            v-model="roundingRule"
            item-text="name"
            item-value="id"
            :items="availableRoundingRules"
            label="Price Rounding Rules"
            placeholder="Select price rounding rules"
            v-bind="rules.priceRoundingRules"
          />
        </div>
        <VCardActions>
          <VSpacer />
          <VBtn @click="closeDialog">Cancel</VBtn>
          <VBtn color="success" :disabled="!isValid" @click="save(agreementRequest)">Save</VBtn>
        </VCardActions>
      </VCard>
    </VForm>
  </VDialog>
</template>

<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0 1rem;
  margin-top: 1rem;

  & > * {
    margin-top: 0;
  }
}
</style>
