// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/agreementconsts.proto (package aurora.core.consts.agreements, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * possible agreement status
 *
 * @generated from enum aurora.core.consts.agreements.AgreementStatus
 */
export enum AgreementStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DRAFT = 1;
   */
  DRAFT = 1,

  /**
   * @generated from enum value: ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: REJECTED = 3;
   */
  REJECTED = 3,

  /**
   * @generated from enum value: CANCELED = 4;
   */
  CANCELED = 4,

  /**
   * @generated from enum value: INACTIVE = 5;
   */
  INACTIVE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(AgreementStatus)
proto3.util.setEnumType(AgreementStatus, "aurora.core.consts.agreements.AgreementStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "DRAFT" },
  { no: 2, name: "ACTIVE" },
  { no: 3, name: "REJECTED" },
  { no: 4, name: "CANCELED" },
  { no: 5, name: "INACTIVE" },
]);

