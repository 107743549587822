import {
  Agreement,
  AgreementStatus,
  PbCompanyInfo,
  RoundingRule,
  SettlementType,
  Timestamp,
  formatRoundingRule,
  formatSettlementType,
  getAgreementStatusColor,
  getAgreementStatusName,
} from '@/connect';
import { alignCenter, alignLeft, alignRight, comparator, component } from './utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { h } from 'vue';
import { VBtn, VChip, VIcon } from 'vuetify/lib';
import { timestampAdapter } from '@/modules/common/components/ag-table/columns/common';
import {
  formatCompanyInfo,
  formatIndependentAmountRate,
  formatTimestampDate,
  formatTimestampTime,
} from '@/modules/agreements/utils';
import { useIsIncoming } from '@/modules/agreements/composables';

export function id(): ColDef<Agreement, string> {
  return {
    field: 'displayId',
    colId: 'displayId',
    headerName: 'Agreement Id',
    ...alignLeft(),
  };
}

const statusChipAdapter = component<{
  status: AgreementStatus;
  isIncoming: boolean;
}>((props) => () => {
  const name = getAgreementStatusName(props.status, props.isIncoming);
  const color = getAgreementStatusColor(props.status, props.isIncoming);
  return h(
    VChip,
    {
      props: { color, 'x-small': true },
      style: 'width: 100%; display: flex; flex-direction: column;',
    },
    [name]
  );
});

export function status(): ColDef<Agreement, AgreementStatus> {
  return {
    field: 'status',
    colId: 'status',
    headerName: 'Status',
    cellRendererSelector: (params) =>
      statusChipAdapter({ status: params.value!, isIncoming: useIsIncoming(params.data!).value }),
    resizable: false,
    pinned: 'left',
    ...alignCenter(),
  };
}

function companyNameFormatter(
  params: ValueFormatterParams<Agreement, PbCompanyInfo | undefined>
): string {
  const companyInfo = params.value;
  if (companyInfo === null || companyInfo === undefined) return '';
  return formatCompanyInfo(companyInfo);
}

export function borrowerCompanyId(): ColDef<Agreement, PbCompanyInfo | undefined> {
  return {
    field: 'borrowerCompany',
    colId: 'borrowerCompany',
    headerName: 'Borrower Company Id',
    valueFormatter: companyNameFormatter,
    ...alignLeft(),
  };
}

export function lenderCompanyId(): ColDef<Agreement, PbCompanyInfo | undefined> {
  return {
    field: 'lenderCompany',
    colId: 'lenderCompany',
    headerName: 'Lending Company Id',
    valueFormatter: companyNameFormatter,
    ...alignLeft(),
  };
}

export function shortName(): ColDef<Agreement, string> {
  return {
    field: 'shortName',
    colId: 'shortName',
    headerName: 'Agreement Short Name',
    ...alignLeft(),
  };
}

export function settlementType(): ColDef<Agreement, SettlementType> {
  return {
    field: 'settlementType',
    colId: 'settlementType',
    headerName: 'Settlement Type',
    valueFormatter: (params) => formatSettlementType(params.value!),
    ...alignLeft(),
  };
}

export function independentAmountRate(): ColDef<Agreement, string> {
  return {
    field: 'independentAmountRate',
    colId: 'independentAmountRate',
    headerName: 'Independent Amount',
    valueFormatter: (params) => formatIndependentAmountRate(params.value!),
    ...alignRight({ hasPostfix: true }),
  };
}

export function roundingRule(): ColDef<Agreement, RoundingRule> {
  return {
    field: 'roundingRule',
    colId: 'roundingRule',
    headerName: 'Price Rounding Rules',
    valueFormatter: (params) => formatRoundingRule(params.value!),
    ...alignLeft(),
  };
}

export function createdBy(): ColDef<Agreement, string> {
  return {
    field: 'proposerUser.userName',
    colId: 'proposerUser.userName',
    headerName: 'Created By',
    ...alignLeft(),
  };
}

export function approvedBy(): ColDef<Agreement, string | undefined> {
  return {
    field: 'responderUser.userName',
    colId: 'responderUser.userName',
    headerName: 'Approved By',
    ...alignLeft(),
  };
}

export function createdDateTime(): ColDef<Agreement, Timestamp | undefined> {
  return {
    field: 'createdAt',
    colId: 'createdAt',
    headerName: 'Created DateTime',
    cellRendererSelector: (params) =>
      timestampAdapter({
        date: formatTimestampDate(params.value),
        time: formatTimestampTime(params.value),
      }),
    comparator: comparator.timestamp,
    ...alignLeft(),
  };
}

export function approvedDateTime(): ColDef<Agreement, Timestamp | undefined> {
  return {
    field: 'approvedAt',
    colId: 'approvedAt',
    headerName: 'Approved DateTime',
    cellRendererSelector: (params) =>
      timestampAdapter({
        date: formatTimestampDate(params.value),
        time: formatTimestampTime(params.value),
      }),
    comparator: comparator.timestamp,
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  item: Agreement;
  columns: number;
  getActionCallbacks: (agreement: Agreement) => {
    view?: () => void;
    duplicate?: () => void;
    edit?: () => void;
    close?: () => void;
    review?: () => void;
  };
}>((props) => () => {
  const { view, duplicate, edit, close, review } = props.getActionCallbacks(props.item);
  const shouldUseGridAreas = props.columns === 4;
  return h(
    'div',
    {
      class: 'actions',
      style: `gap: 0.5rem; display: grid; ${!shouldUseGridAreas ? '' : 'grid-template-areas: "view duplicate edit close";'} grid-template-columns: repeat(${props.columns}, 1fr);`,
    },
    [
      view &&
        h(
          VBtn,
          {
            class: 'icon-action',
            style: !shouldUseGridAreas ? '' : 'grid-area: view;',
            attrs: {
              'data-test': 'agreement-view',
              icon: true,
              'x-small': true,
              title: 'View',
            },
            on: { click: view },
          },
          [h(VIcon, {}, 'mdi-eye')]
        ),
      duplicate &&
        h(
          VBtn,
          {
            class: 'icon-action',
            style: !shouldUseGridAreas ? '' : 'grid-area: duplicate;',
            attrs: {
              'data-test': 'agreement-duplicate',
              icon: true,
              'x-small': true,
              title: 'Duplicate',
            },
            on: { click: duplicate },
          },
          [h(VIcon, {}, 'mdi-plus-circle-multiple-outline')]
        ),
      edit &&
        h(
          VBtn,
          {
            class: 'icon-action',
            style: !shouldUseGridAreas ? '' : 'grid-area: edit;',
            attrs: {
              'data-test': 'agreement-edit',
              icon: true,
              'x-small': true,
              title: 'Edit',
            },
            on: { click: edit },
          },
          [h(VIcon, {}, 'mdi-pencil')]
        ),
      close &&
        h(
          VBtn,
          {
            class: 'icon-action',
            style: !shouldUseGridAreas ? '' : 'grid-area: close;',
            attrs: {
              'data-test': 'agreement-close',
              icon: true,
              'x-small': true,
              title: 'Close',
            },
            on: { click: close },
          },
          [h(VIcon, {}, 'mdi-close')]
        ),
      review &&
        h(
          VBtn,
          {
            class: 'icon-action',
            style: !shouldUseGridAreas ? '' : 'grid-column: 2 / span 2;',
            attrs: {
              'data-test': 'agreement-review',
              icon: true,
              'x-small': true,
              title: 'Review',
            },
            on: { click: review },
          },
          [h(VIcon, {}, 'mdi-phone-incoming')]
        ),
    ]
  );
});

export function actions(
  columns: number,
  getActionCallbacks: (agreement: Agreement) => {
    view?: () => void;
    duplicate?: () => void;
    edit?: () => void;
    close?: () => void;
    review?: () => void;
  }
): ColDef<Agreement> {
  return {
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        item: params.data as Agreement,
        columns,
        getActionCallbacks,
      }),
    pinned: 'right',
    lockVisible: true,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
