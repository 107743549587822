// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/uptimeconsts.proto (package aurora.core.consts.uptime, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.uptime.UptimeMonitor
 */
export enum UptimeMonitor {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ILERT = 1;
   */
  ILERT = 1,

  /**
   * @generated from enum value: CHECKLY = 2;
   */
  CHECKLY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UptimeMonitor)
proto3.util.setEnumType(UptimeMonitor, "aurora.core.consts.uptime.UptimeMonitor", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "ILERT" },
  { no: 2, name: "CHECKLY" },
]);

