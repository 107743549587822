<script setup lang="ts">
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import AgreementsTable from '@/modules/agreements/components/AgreementsTable.vue';
import AgreementView from '@/modules/agreements/components/AgreementView.vue';
import AgreementEditor from '@/modules/agreements/components/AgreementEditor.vue';
import { shallowRef } from 'vue';
import {
  Agreement,
  ApproveAgreementRequest,
  ConnectResult,
  CreateAgreementRequest,
  ModifyAgreementRequest,
  RejectAgreementRequest,
  serviceAgreements,
} from '@/connect';
import { useDialogManager } from '@/plugins/dialog-manager';
import i18n from '@/localisation/i18n';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import { EventReadyConfig } from '@/modules/common/components/ag-table/AgTableServer.vue';
import { useHasPermission } from '@/modules/agreements/composables';

const dialogManager = useDialogManager();
const snackbarManager = useSnackbarManager();

const agreementView = shallowRef<null | Agreement>(null);
const agreementSource = shallowRef<null | Agreement>(null);
const agreementRequest = shallowRef<null | ModifyAgreementRequest | CreateAgreementRequest>(null);

let refreshTable = (_config: { purge: boolean }) => {
  return;
};
function onTableReady(event: EventReadyConfig) {
  refreshTable = event.refresh;
}

function agreementNew() {
  agreementSource.value = null;
  agreementRequest.value = new CreateAgreementRequest();
}

function agreementEdit(agreement: Agreement) {
  agreementSource.value = agreement;
  agreementRequest.value = new ModifyAgreementRequest(agreement);
}

function agreementDuplicate(agreement: Agreement) {
  agreementSource.value = agreement;
  agreementRequest.value = new CreateAgreementRequest(agreement);
  agreementRequest.value.shortName = '';
}

function agreementClose(agreement: Agreement) {
  dialogManager.ask({
    title: 'Close Agreement',
    color: 'error',
    message: `Are you sure you want to close "${agreement.shortName}" agreement?`,
    acceptText: `Close the Agreement`,
    onAccept: async () => {
      const result = await serviceAgreements.cancelAgreement(agreement);
      refreshTable({ purge: false });
      handleConnectResult(result, `Closed "${agreement.shortName}" agreement`);
    },
    rejectText: i18n.t('cancelButton'),
  });
}

function agreementReject(agreement: Agreement, onRejected: () => void) {
  dialogManager.ask({
    title: 'Reject Agreement',
    color: 'error',
    message: `Are you sure you want to reject "${agreement.displayId}" agreement?`,
    acceptText: `Reject the Agreement`,
    onAccept: async () => {
      const result = await serviceAgreements.rejectAgreement(
        new RejectAgreementRequest({ id: agreement.id })
      );
      refreshTable({ purge: false });
      handleConnectResult(result, `Rejected "${agreement.displayId}" agreement`);
      onRejected();
    },
    rejectText: i18n.t('cancelButton'),
  });
}

async function agreementApprove(request: ApproveAgreementRequest) {
  const result = await serviceAgreements.approveAgreement(request);
  refreshTable({ purge: false });
  handleConnectResult(result, `Approved "${request.shortName}" agreement`);
}

async function agreementSave(
  request: ModifyAgreementRequest | CreateAgreementRequest
): Promise<void> {
  if (request instanceof ModifyAgreementRequest) {
    const result = await serviceAgreements.modifyAgreement(request);
    refreshTable({ purge: false });
    handleConnectResult(result, `Modified "${request.shortName}"`);
  }
  if (request instanceof CreateAgreementRequest) {
    const result = await serviceAgreements.createAgreement(request);
    refreshTable({ purge: false });
    handleConnectResult(result, `Created "${request.shortName}"`);
  }
}

function handleConnectResult(result: ConnectResult<unknown>, successMessage: string) {
  snackbarManager.show(
    result.success
      ? {
          message: successMessage,
          color: 'green',
        }
      : {
          message: i18n.t(result.error, result.details) as string,
          color: 'red',
        }
  );
}

const canCreateAgreement = useHasPermission('agreement-create');
const canEditOrCreateAgreement = useHasPermission('agreement-create', 'agreement-edit');

const reservedShortNames = shallowRef(new Set());
</script>

<template>
  <div class="wrapper d-flex flex-column fill-height">
    <DashboardPanel no-collapse title="Agreements">
      <div v-if="canCreateAgreement" class="d-flex gap-1 ma-4">
        <v-btn color="primary" @click="agreementNew">Add agreement</v-btn>
      </div>

      <AgreementView
        :agreement="agreementView"
        :reserved-short-names="reservedShortNames"
        @approve="agreementApprove"
        @cancel="agreementView = null"
        @close="agreementClose"
        @duplicate="agreementDuplicate"
        @edit="agreementEdit"
        @reject="agreementReject"
      />
      <AgreementEditor
        v-if="canEditOrCreateAgreement"
        :reserved-short-names="reservedShortNames"
        :source="agreementSource"
        :value="agreementRequest"
        @cancel="agreementRequest = null"
        @save="agreementSave"
      />
      <AgreementsTable
        data-test="agreements-table"
        @close="agreementClose"
        @duplicate="agreementDuplicate"
        @edit="agreementEdit"
        @ready="onTableReady"
        @update:reserved-short-names="(value) => (reservedShortNames = value)"
        @view="(value) => (agreementView = value)"
      />
    </DashboardPanel>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}
</style>
